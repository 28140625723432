body {
    background: rgb(6, 0, 12);
    color: white;
}

.img-project {
    transition: 0.5s;
}

.img-project:hover {
    transform: scale(1.05);
}